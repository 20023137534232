<template>
    <div>
        <modal name="my-modal" height="auto">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <h3 class="card-header-title" id="exampleModalCenterTitle">
                           Data Registrasi Siswa
                        </h3>
                        <button type="button" class="close" @click.prevent="hide_modal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <iframe v-if="fileRaportPDF" :src="fileRaportPDF" width="100%" height="500px" style="border:none;"></iframe>
            </div>
        </modal>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- Breadcrumb -->
                <div class="position-fixed w-100" style="top:0;z-index:99;transform:translateX(-1px);">
                    <div class="d-flex mb-4 pt-3 pb-3 border-bottom align-items-center bg-white">
                        <a href="javascript:history.back()"><span class="fe fe-arrow-left mr-1"></span>Kembali</a>
                        <span class="text-muted mx-2">|</span>
                        <div class="text-muted">Data Bakal Peserta</div>
                        <span class="text-muted mx-2"><span class="fe fe-arrow-right-circle mr-1 text-sm"></span></span>
                        <div class="text-muted">{{ detailsetformulir.nama_formulir }}</div>
                    </div>
                </div>
                <!-- -->
                <div class="d-block mb-4 pt-5 mt-3">
                    <div class="d-flex align-items-center">
                        <div class="mr-3">
                            <img src="https://scolacdn.com/frontend/argonne-img/user-icon-teacher.png" width="38">
                        </div>
                        <div>
                            <h1 class="header-title">
                                List Bakal Peserta Belajar
                            </h1>
                            <div class="d-block">
                                Menu ini digunakan untuk mengelola data List Bakal Peserta.
                            </div>
                        </div>
                    </div>
                </div>
                <!-- CONTENT -->
                <div class="card shadow-sm">
                    <!-- Card Header -->
                    <div class="d-flex align-items-center justify-content-between px-4 py-3 border-bottom">
                        <div class="d-flex align-items-center">
                            <div class="mr-4 border-right pr-4"><span class="small">Kode Formulir:</span>
                                <div class="d-block font-weight-bold text-sm">{{ detailsetformulir.kode_formulir }}</div>
                            </div>
                            <div class="mr-4 border-right pr-4"><span class="small">Unit:</span>
                                <div class="d-block font-weight-bold text-sm">{{ detailsetformulir.unit_title }}</div>
                            </div>
                            <div class="mr-4 border-right pr-4"><span class="small">Sub-unit:</span>
                                <div v-if="detailsetformulir.sub_unit_title" class="d-block font-weight-bold text-sm">{{ detailsetformulir.sub_unit_title }}</div>
                                <div v-else class="d-block font-weight-bold text-sm">-</div>
                            </div>
                            <div class="mr-4 border-right pr-4"><span class="small">Periode:</span>
                                <div class="d-block font-weight-bold text-sm">{{ detailsetformulir.nama_periode }}</div>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex align-items-center">
                                <div class="mr-3 text-right">
                                    <span class="small">Peserta</span>
                                    <div class="d-block font-weight-bold">{{ dataRegister.total_data }} siswa</div>
                                </div>
                                <div class="avatar-sm rounded-lg bg-primary-soft">
                                    <div class="d-flex w-100 h-100 align-items-center justify-content-center">
                                        <span class="fe fe-user h3 m-0 text-primary"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -->
                    <div class="card-body">
                        <div class="d-block border-bottom pb-3">
                            <div class="d-flex align-items-center mb-3">
                                <div class="avatar bg-primary mr-3 rounded-circle mt-n1">
                                    <div class="d-flex w-100 h-100 align-items-center justify-content-center">
                                        <span class="fe fe-file h3 m-0 text-white"></span>
                                    </div>
                                </div>
                                <div class="mt-1">
                                    <div class="h2 mb-1">{{ detailsetformulir.nama_formulir }}</div>
                                    <div class="d-block">Gelombang <span class="fe fe-chevron-right mx-1 text-muted"></span> {{ detailsetformulir.nama_gelombang }}</div>
                                </div>
                            </div>
                            <div class="text-sm d-block px-3 py-2 bg-light rounded">
                                <table>
                                    <tr>
                                        <td class="pr-2">
                                            <span class="fe fe-info mr-2 text-muted"></span>Ket:
                                        </td>
                                        <td v-if="detailsetformulir.keterangan">{{ detailsetformulir.keterangan }}</td>
                                        <td v-else>-</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow-sm">
                    <div class="d-block p-4 border-bottom">
                        <div class="row align-items-center">
                            <div class="col-md-3 pr-md-0">
                                <select class="form-control custom-select form-control-rounded text-sm shadow-sm" v-model="status" v-on:change="getData(true)">
                                    <option value="">Lihat Semua Status</option>
                                    <option value="LOLOS">Lolos</option>
                                    <option value="TIDAK LOLOS">Tidak Lolos</option>
                                </select>
                            </div>
                            <div class="col-md-2 pr-md-0">
                                <select class="form-control custom-select form-control-rounded shadow-sm text-sm" v-model="type_kategori" v-on:change="getData()">
                                    <option value="">Lihat Semua Kategori</option>
                                    <option v-for="row in listKategori" :key="row.id" :value="row.id">{{row.kategori}}</option>
                                </select>
                            </div>
                            <div class="col-md-4 pr-md-0">
                                <!-- Search -->
                                <form>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text text-sm">
                                                <i class="fe fe-search"></i>
                                            </span>
                                        </div>
                                        <input type="search" class="form-control text-sm search" v-model="keywords" v-on:keyup.enter="getData(true)" placeholder="Cari data berdasarkan kode registrasi/nama/email lalu tekan enter...">
                                    </div>
                                </form>
                            </div>
                            <div class="col-md-2">
                                <download-excel :fetch="getDataExport" :name="fileName" :header="header">
                                    <button class="btn btn-block btn-outline-primary text-sm"><span class="fe fe-download-cloud mr-2"></span>Export to excel</button>
                                </download-excel>
                            </div>
                            <div v-if="$beta_cetak == true && (detailsetformulir.nama_formulir == 'SMP' || detailsetformulir.nama_formulir == 'SMA')" class="col-md-1">
                                <button v-if="!loadingUnduh" @click.prevent="printAllUser(idForm)" type="button" class="btn btn-block btn-secondary text-sm"><i class="fe fe-printer"></i></button>
                                <button v-if="loadingUnduh" type="button" disabled class="btn btn-block btn-secondary text-sm">Poses..</button>
                            </div>
                            <div v-if="$beta_cetak == true && detailsetformulir.nama_formulir == 'TK'" class="col-md-1">
                                <button v-if="!loadingUnduh" @click.prevent="printAllTk(idForm)" type="button" class="btn btn-block btn-secondary text-sm"><i class="fe fe-printer"></i></button>
                                <button v-if="loadingUnduh" type="button" disabled class="btn btn-block btn-secondary text-sm">Poses..</button>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-sm table-nowrap card-table">
                            <thead>
                                <tr>
                                    <th>Kode registrasi</th>
                                    <th class="w-60">Nama</th>
                                    <th>Email</th>
                                    <th>phone</th>
                                    <th>Kategori</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="dataRegister.length == 0 && !fetching">
                                <tr>
                                    <td colspan="7">
                                        <div class="alert alert-light m-0" role="alert">
                                            <div class="d-flex align-items-center">
                                                <div>Data tidak tersedia..</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-if="fetching">
                                <tr>
                                    <td colspan="7">
                                        <div class="alert alert-light m-0" role="alert">
                                            <div class="d-flex align-items-center">
                                                <div class="spinner-border spinner-border-sm mr-3" role="status"></div>
                                                <div>Sedang mengambil data..</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-if="!fetching" class="list">
                                <tr v-for="(register, index) in dataRegister" :key="index">
                                    <td>
                                        {{ register.kode_registrasi }}
                                    </td>
                                    <td>
                                        {{ register.fullname }}
                                    </td>
                                    <td>
                                        {{ register.email }}
                                    </td>
                                    <td>
                                        {{ register.phone }}
                                    </td>
                                    <td>
                                        {{ register.kategori }}
                                    </td>
                                    <td>
                                        <span v-if="register.status_cicilan == 0" class="badge badge-success mr-3">{{ register.status_registrasi }}</span>
                                        <span v-if="register.status_registrasi == 'LOLOS' && register.status_cicilan == 1 && register.sisa_cicilan == 0" class="badge badge-success mr-3">LUNAS</span>
                                        <span v-if="register.status_registrasi == 'LOLOS' && register.status_cicilan == 1 && register.sisa_cicilan != 0" class="badge badge-warning mr-3">CICILAN</span>
                                    </td>
                                    <td>
                                        <router-link :to="{ name:'registrasi.detail',params: { id: register.id }}" class="btn btn-primary btn-sm"><i class="fe fe-eye mr-2"></i>Lihat</router-link>
                                        <button v-if="$beta_cetak == true && register.status_registrasi == 'LOLOS' && (detailsetformulir.nama_formulir == 'SMP' || detailsetformulir.nama_formulir == 'SMA')" @click.prevent="printUser(register.id, register.users_id)" type="button" class="ml-1 btn btn-sm btn-secondary text-sm"><i class="fe fe-printer"></i></button>
                                        <button v-if="$beta_cetak == true && register.status_registrasi == 'LOLOS' && detailsetformulir.nama_formulir == 'TK'" @click.prevent="printTk(register.id, register.users_id)" type="button" class="ml-1 btn btn-sm btn-secondary text-sm"><i class="fe fe-printer"></i></button>
                                    
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- Jalankan empty state -->
                    <!-- <div v-if="dataRegister.length==0 && !fetching">
                                <div class="d-block p-4">
                                    <div class="alert alert-light m-0" role="alert">
                                        Data tidak tersedia
                                    </div>
                                </div>
                            </div> -->
                    <div class="card-footer border-top-0">
                        <div style="float:right">
                            <!-- <ul class="pagination mb-0"></ul> -->
                            <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="changePage()"></v-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        components: {
            vPagination
        },
        data() {
            return {
                fileName: "data-pendaftaran",
                header: "List Bakal Peserta",
                baseUrl: this.$apiconfig,
                idForm: this.$route.params.id,
                loadingUnduh: false,
                dataRegister: [],
                listKategori: [],
                fileRaportPDF: '',
                detailsetformulir: {
                    kode_formulir: '',
                    nama_formulir: '',
                    unit_id: '',
                    sub_unit_id: '',
                    jadwal_tes: '',
                    url_tes: '',
                    informasi_tes: '',
                    payment_gateway_information: '',
                    external_gateway_information: '',
                    informasi_reg_ulang: '',
                    countsiswa: ''
                },
                type_kategori: '',
                fetching: true,
                pagination: {
                    currentPage: 1,
                    totalPages: 0,
                    limit: 5,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                status: ''
            }
        },
        created() {
            if (localStorage.getItem("page_re_registration_student_detail") !== null) {
                this.pagination.currentPage = parseInt(localStorage.getItem("page_re_registration_student_detail"));
            }
            this.getData();
            this.getDataFormulir();
            this.getKategori();
        },
        methods: {
            // CHANGE PAGE PAGINATION
            changePage() {
                localStorage.setItem("page_re_registration_student_detail", this.pagination.currentPage);
                this.getData();
            },
            // GET DATA REGISTER
            getData(reset = false) {
                console.log('Versi 26 Feb 2024 13:49');
                if (reset) {
                    this.pagination.currentPage = 1;
                    this.pagination.totalPages = 0;
                    localStorage.setItem("page_re_registration_student_detail", this.pagination.currentPage);
                }
                this.dataRegister = [];
                this.fetching = true;
                this.$http.get(this.baseUrl + `admin/registrasi-ulang/data/siswabaru/setupformulir`, {
                        params: {
                            id: this.$route.params.id,
                            status: this.status,
                            kategori: this.type_kategori,
                            keywords: this.keywords,
                            page: this.pagination.currentPage
                        }
                    })
                    .then((response) => {
                        if (response.data.status) {
                            this.dataRegister = response.data.data;
                        }
                        this.dataRegister.total_data = response.data.total_data;
                        this.pagination.totalPages = response.data.total_page;
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    })
            },
            printAllUser(idForm) {
                this.loadingUnduh = true;
                this.$http.get(this.baseUrl + `admin/registrasi/print-filter?id=`+idForm, { responseType: 'blob' })
                    .then((response) => {
                        this.loadingUnduh = false;
                        var file = new Blob([response.data], { type: 'application/pdf' });
                        
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.download = 'data-registrasi.zip';
                        document.body.appendChild(a);
                        a.click();

                        // Membersihkan elemen <a> setelah unduhan selesai
                        window.URL.revokeObjectURL(fileURL);
                        
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            },
            printAllTk(idForm) {
                this.loadingUnduh = true;
                this.$http.get(this.baseUrl + `admin/registrasi/print-filter-tk?id=`+idForm, { responseType: 'blob' })
                    .then((response) => {
                        this.loadingUnduh = false;
                        var file = new Blob([response.data], { type: 'application/pdf' });
                        
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.download = 'data-registrasi.zip';
                        document.body.appendChild(a);
                        a.click();

                        // Membersihkan elemen <a> setelah unduhan selesai
                        window.URL.revokeObjectURL(fileURL);
                        
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            },
            printUser(regis, user) {
                this.$http.get(this.baseUrl + `admin/registrasi/print-user?id=`+regis+`&user=`+user, { responseType: 'blob' })
                    .then((response) => {
                        var file = new Blob([response.data], { type: 'application/pdf' });
                        
                        var fileURL = URL.createObjectURL(file);
                        this.fileRaportPDF = fileURL;
                        this.$modal.show('my-modal');
                        
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            },
            printTk(regis, user) {
                this.$http.get(this.baseUrl + `admin/registrasi/print-user-tk?id=`+regis+`&user=`+user, { responseType: 'blob' })
                    .then((response) => {
                        var file = new Blob([response.data], { type: 'application/pdf' });
                        
                        var fileURL = URL.createObjectURL(file);
                        this.fileRaportPDF = fileURL;
                        this.$modal.show('my-modal');
                        
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            },
            hide_modal() {
                 this.$modal.hide('my-modal');
            },
            getDataFormulir() {
                // GET DATA detailsetformulir
                this.$http.get(this.baseUrl + `admin/gelombang/detail/formulir?id=${this.$route.params.id}`)
                    .then(response => {
                        this.detailsetformulir.nama_gelombang = response.data.data[0].nama_gelombang;
                        this.detailsetformulir.nama_periode = response.data.data[0].nama_periode;
                        this.detailsetformulir.nama_formulir = response.data.data[0].nama_formulir;
                        this.detailsetformulir.kode_formulir = response.data.data[0].kode_formulir;
                        this.detailsetformulir.unit_title = response.data.data[0].unit_title;
                        this.detailsetformulir.sub_unit_title = response.data.data[0].sub_unit_title;
                        this.detailsetformulir.jadwal_tes = response.data.data[0].jadwal_tes;
                        this.detailsetformulir.url_tes = response.data.data[0].url_tes;
                        this.detailsetformulir.payment_gateway_information = response.data.data[0].payment_gateway_information;
                        this.detailsetformulir.external_gateway_information = response.data.data[0].external_gateway_information;
                        this.detailsetformulir.informasi_tes = response.data.data[0].informasi_tes;
                        this.detailsetformulir.informasi_reg_ulang = response.data.data[0].informasi_reg_ulang;
                        this.detailsetformulir.deskripsi_formulir = response.data.data[0].deskripsi_formulir;
                        this.detailsetformulir.deskripsi_lulus = response.data.data[0].deskripsi_lulus;
                        this.detailsetformulir.deskripsi_tidak_lulus = response.data.data[0].deskripsi_tidak_lulus;
                        this.detailsetformulir.countsiswa = response.data.data[0].countsiswa;
                        this.detailsetformulir.status = response.data.data[0].status;
                    })
                    .catch(() => {
                        this.fetching = false;
                    });
            },
            getKategori() {
                this.listKategori = [];
                this.$http.get(this.baseUrl + `admin/registrasi/data/kategori`)
                    .then((response) => {
                        this.listKategori = response.data.data;
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            async getDataExport() {
                const response = await this.$http.get(this.baseUrl + `admin/registrasi-ulang/data/siswabaru/setupformulir/excel`, {
                    params: {
                        id: this.$route.params.id
                    }
                });
                return response.data.data;
            }
        }
    }
</script>